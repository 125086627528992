@use "assets/styles/global" as *;

.page {
  min-height: 100%;
  min-height: 100vh;

  background-color: #f5f6f7;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  display: flex;
  justify-content: flex-end;
}

.page-view {
  width: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
}

.page-content {
  box-sizing: border-box;
}

.with-collapsed-navbar {
  .sidebar {
    @include sm-md {
      display: none;
    }

    @include md {
      width: $navbar-collapsed-width;
    }
  }

  .page-view {
    display: block;

    @include md {
      display: block;
      width: calc(100% - $navbar-collapsed-width);
    }
  }
}

.with-expanded-navbar {
  .sidebar {
    width: 100%;

    @include md {
      width: $navbar-width;
    }
  }

  .page-view {
    display: none;

    @include md {
      display: block;
      width: calc(100% - $navbar-width);
    }
  }
}
