@use "assets/styles/global" as *;
@use "assets/styles/mixins/icon";

$prompt-box-width: 300px;

@mixin option-icon {
  height: 26px;
  width: 4rem;
  background-position: right;
  margin-bottom: 3px;
}

.create-new-prompt-container {
  position: absolute;
  z-index: 1;
  top: 50px;
  right: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  cursor: pointer;

  @include sm {
    display: none;
  }
}

.prompt-box {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 10px #1b214026;

  .prompt-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: $background-color;
  }

  .prompt-option:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

  .option-text {
    font-size: 14px;
    padding: 16px 0;
  }
}

.create-new-prompt {
  width: $prompt-box-width;
  .prompt-option:last-child {
    &:hover {
      background-color: $primary-color;

      .option-text {
        color: $brand-text-color;
        font-weight: 500;
      }

      .option-icon {
        @include icon.white("arrow-right");
        @include option-icon;
      }
    }
  }

  .option-icon {
    @include icon.black("arrow-right");
    @include option-icon;
  }
}

.create-new-prompt-options {
  position: absolute;
  right: $prompt-box-width;
  bottom: 0;
  width: $prompt-box-width;
}
