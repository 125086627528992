@use "assets/styles/global" as *;
@use "assets/styles/mixins/icon";

.header {
  padding: 20px $mobile-gutter;
  margin: 9px;
  background-color: $background-color;
  box-shadow: 0px 4px 12px #42467E0A;
  border-radius: 8px;
  border-top-right-radius: 8px;

  @include md {
    padding: 20px $desktop-gutter;
  }
}

.header-title {
  font-size: 20px;
  color: $secondary-text-color;

  @include sm {
    padding-left: 40px;
  }
}

.subheader-content {
  border-top: 1px solid $border-color;
  margin-top: 20px;
  padding-top: 20px;
}

.burger-icon {
  position: absolute;
  top: 24px;
  left: 1.5rem;

  @include icon.black("burger");
  cursor: pointer;

  @include md {
    display: none;
  }
}
