@use "assets/styles/global" as *;

.actions-section {
  padding-top: 1.5rem;
  margin-top: 2rem;

  @include md {
    padding-top: 1.5rem;
  }

  &.section-size-sm {
    border-top: 0;
    padding: 0;
  }
}

.form-actions {
  display: flex;
  align-items: center;

  .delete-action-button {
    margin-left: auto;
  }
}
