@use "assets/styles/global" as *;

.navigation-tabs {
  display: flex;
  align-items: center;
  gap: 8px;

  .tabs {
    display: flex;
    align-items: center;
    gap: 1rem;
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .tab {
    flex-shrink: 0;
    padding: 8px 18px;
    text-transform: uppercase;
    text-decoration: none;
    color: $quarternary-text-color;
    font-size: 14px;
    font-weight: 500;

    &.active-tab {
      background-color: #eef3ff; // $primary-color with opacity 0.08
      color: $primary-color;
      font-weight: bold;
      border-radius: 6px;
    }
  }

  .scroll-button {
    background-color: transparent;
    border: none;
    padding: 0;
    line-height: 1;
    width: 20px;
    flex-shrink: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &.left-scroll-button {
      transform: rotate(180deg);
      margin-left: -16px;
    }

    &.right-scroll-button {
      margin-right: -16px;
    }

    &.hidden-scroll-button {
      pointer-events: none;

      img {
        visibility: hidden;
      }
    }

    img {
      max-width: 100%;
    }
  }
}
