@use "assets/styles/global" as *;

.environment-indicator {
  display: none;

  @include md {
    display: block;
    background-color: $primary-color;
  }

  .environment-name {
    padding: 8px 12px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: $brand-text-color;
  }
}

.sidebar > .environment-indicator {
  padding: 0;
}
