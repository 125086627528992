@use "assets/styles/global" as *;

.modal-container {
  border-radius: 8px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 9px;
  background-color: $background-color;

  .modal-header {
    padding: 1.5rem $mobile-gutter;

    @include md {
      padding: 1.5rem $desktop-gutter;
    }

    .modal-navigation .navigation-text {
      @include sm {
        font-size: 14px;
      }
    }
  }
}
