@use "assets/styles/global" as *;

$sidebar-background-color: #1b2140;

.sidebar {
  position: fixed;
  left: 0;
  height: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  background-color: $sidebar-background-color;

  > div {
    padding: 2rem 1.5rem;
  }
}

.with-collapsed-navbar {
  .sidebar > div {
    padding-top: 1rem;
  }
  
  .sidebar-location {
    opacity: 0;
  }
}