@use "assets/styles/global" as *;
@use "assets/styles/mixins/icon";

$sidebar-active-background-color: #3f4662;

$sidebar-text-color: #b7bcc7;
$sidebar-active-text-color: #fff;

.sidebar .sidebar-list {
  padding: 0;
  margin: 0 10px;
}

.sidebar-list-item {
  display: block;
  padding: 6px 5px;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;

  &.active {
    background-color: $sidebar-active-background-color;
    font-weight: 500;

    .sidebar-list-item-name {
      color: $sidebar-active-text-color;
    }

    .active-indicator {
      opacity: 1;
    }
  }

  .sidebar-list-item-name {
    color: $sidebar-text-color;
  }

  .sidebar-list-item-content {
    display: flex;
    align-items: center;
  }

  .sidebar-icon {
    flex-shrink: 0;
    margin-right: 16px;
    margin-left: 20px;
  }

  .active-indicator {
    opacity: 0;
    flex-shrink: 0;
    width: 2px;
    height: 36px;
    border-radius: 2px;
    background-color: $sidebar-active-text-color;
  }
}

.with-collapsed-navbar {
  .sidebar-list-item-name,
  .active-indicator {
    display: none;
  }
  .sidebar-list-item {
    .sidebar-list-item-content {
      height: 36px;
    }

    .sidebar-icon {
      margin: 0;
      flex-grow: 1;
    }
  }
}

.sidebar-setup .sidebar-icon {
  @include icon.navigation-outlined("setup");
  &.active-icon {
    @include icon.navigation-filled("setup");
  }
}
.sidebar-locations .sidebar-icon {
  @include icon.navigation-outlined("locations");
  &.active-icon {
    @include icon.navigation-filled("locations");
  }
}
.sidebar-modules .sidebar-icon {
  @include icon.navigation-outlined("modules");
  &.active-icon {
    @include icon.navigation-filled("modules");
  }
}
.sidebar-features .sidebar-icon {
  @include icon.navigation-outlined("features");
  &.active-icon {
    @include icon.navigation-filled("features");
  }
}
