@use "assets/styles/global" as *;

.edit-modal {
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  
  .modal-content-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 34px;
  }

  .modal-navigation {
    cursor: pointer;
    
    img {
      height: 24px;
      opacity: 0.6;
    }
  }

  .modal-navigation.back {
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    .navigation-text {
      margin-left: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include md {
        margin-left: 8px;
      }
    }
  }
  .modal-body {
    padding: 2rem 0;
    margin: 0 auto;
    background-color: $background-color;
    width: 90%;

    @include md {
      padding: 2rem 0;
      width: 50%;
    }
  }
}
