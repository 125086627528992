@use "assets/styles/global" as *;

$table-row-height: 50px;

.table-body {
  font-size: 16px;

  @include md {
    overflow-x: auto;
  }
}

.table-action {
  display: flex;
  justify-content: flex-end;
}

.table-row {
  display: grid;
  grid-template-columns: 3fr 3fr 2fr;
  min-width: 100%;
  background-color: $background-color;
  border-radius: 6px;
  margin-bottom: 4px;

  cursor: pointer;

  // Header row styling
  &:first-child {
    background-color: transparent;
    cursor: initial;

    .table-column {
      border: none;
      background-color: transparent;

      > div {
        height: auto;
      }
    }

    .column-title {
      color: $tertiary-text-color;
      font-size: 14px;
      text-transform: uppercase;
    }

    .table-column:first-child {
      .column-title {
        line-height: initial;
      }
    }
  }
}

.table-column {
  padding: 5px;
  box-sizing: border-box;
  background-color: $background-color;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  // Name column
  &:first-child {
    min-width: 300px;

    .column-title-container {
      display: flex;
      align-items: center;
    }
    .border {
      flex-shrink: 0;
      height: $table-row-height;
      width: 3px;
      border-radius: 2px;
      background-color: $primary-color;
    }
    .column-title {
      line-height: $table-row-height;
      padding: 0 25px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &:not(:first-child) {
    font-size: 14px;
    @include sm {
      display: none;
    }
  }

  img {
    max-height: 18px;
  }
}
