@use "assets/styles/global" as *;

.table-view {
  padding: 20px $mobile-gutter;

  @include md {
    padding: 22px $desktop-gutter;
  }
}

.table-title-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    flex-shrink: 0;
  }
}

.table-title {
  font-size: 30px;
  margin-bottom: 22px;
  font-weight: 600;
}
