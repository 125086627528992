$primary-color: #0051ff;
$secondary-color: #1b2140;

$primary-accent-1: #d6e3ff;
$primary-accent-2: #e0eaff;
$primary-accent-3: #ebf1ff;

$secondary-accent-1: #262c4a;
$secondary-accent-2: #3f4662;

$text-color: #111126;
$secondary-text-color: #34364d;
$tertiary-text-color: #51566b;
$quarternary-text-color: #787b8a;

$brand-text-color: white;

$link-color: $secondary-color;

$background-color: white;

$error-color: #d32f2f;
$error-button-color: #ffeded;
$error-button-text-color: #ff3a33;

$box-shadow-color: #42467e1f;
$border-color: #DFE1E6;

// Font
$main-font: "Poppins", sans-serif;

// Spacing
$mobile-gutter: 1rem;
$desktop-gutter: 34px;

$navbar-width: 300px;
$navbar-collapsed-width: 65px;
