@use "assets/styles/global" as *;

.form-section {
  background-color: $background-color;

  margin-top: 2rem;
  border-top: 1px solid $border-color;

  padding-top: 2rem;

  @include md {
    padding-top: 2rem;
  }

  // Nested form sections
  &:nth-child(1),
  .form-section {
    padding-top: 0;
    margin: 0;
    border-top: none;
  }

  .input-container {
    margin-top: 1rem;
    flex-grow: 1;
  }
}

.form-section-title {
  font-size: 18px;
  margin: 0 0 1rem 0;
  width: 100%;
}

.form-inputs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

.form .form-section .input-container p.helper-text {
  color: rgba(0, 0, 0, 0.6);
}
