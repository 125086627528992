@use "assets/styles/global" as *;
@use "assets/styles/mixins/icon";

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo-title {
    color: $brand-text-color;
    font-size: 20px;
  }
  .logo, .collapsed-logo {
    max-height: 30px;
  }
}

.with-collapsed-navbar {
  .sidebar-header {
    @include md {
      flex-direction: column-reverse;
      gap: 1rem;
    }
  }

  .logo, .logo-title {
    display: none;
  }

  .sidebar-toggle-icon {
    transform: rotate(180deg);
  }
}

.with-expanded-navbar {
  .collapsed-logo {
    display: none;
  }
}

.sidebar-toggle-icon {
  @include icon.white("close");
  cursor: pointer;

  @include md {
    @include icon.white("arrow-left");

    background-color: #565F83;
    border-radius: 6px;
  }
}
