@use "assets/styles/global" as *;

.popup {
  .title-container {
    padding: 1rem 2rem;
    background-color: $primary-color;

    .title {
      color: $brand-text-color;
      font-weight: 500;
    }
  }

  .content {
    padding: 1.5rem 2rem;
    font-size: 14px;
  }

  .popup-actions {
    padding: 0 1.5rem 1rem 1.5rem;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
