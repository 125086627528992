@use "assets/styles/global" as *;

.button {
  font-family: $main-font;
  text-align: center;
  border: none;

  &:focus {
    outline: none;
  }
  &:hover:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.7;
  }

  &.text-button {
    background-color: transparent;
    color: $text-color;
  }

  // Sizes
  &.size-xs {
    font-size: 12px;
    height: 36px;
    padding: 0 15px;
  }
  
  &.size-sm {
    font-size: 14px;
    height: 42px;
    padding: 0 17px;
  }

  &.size-md {
    font-size: 16px;
    height: 47px;
    padding: 0 20px;
  }

  &.size-lg {
    font-size: 18px;
    height: 50px;
    padding: 0 22px;
  }
}
