@-webkit-keyframes rotate-forwards {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
  }
}
@-moz-keyframes rotate-forwards {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(180deg);
  }
}
@-o-keyframes rotate-forwards {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(180deg);
  }
}
@keyframes rotate-forwards {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@-webkit-keyframes rotate-backwards {
  0% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-moz-keyframes rotate-backwards {
  0% {
    -moz-transform: rotate(180deg);
  }
  100% {
    -moz-transform: rotate(0deg);
  }
}
@-o-keyframes rotate-backwards {
  0% {
    -o-transform: rotate(180deg);
  }
  100% {
    -o-transform: rotate(0deg);
  }
}
@keyframes rotate-backwards {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
