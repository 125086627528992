@mixin icon {
  width: 2rem;
  height: 2rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin black($name) {
  @include icon;
  background-image: url("../../img/icons/black/" + $name + ".svg");
}
@mixin white($name) {
  @include icon;
  background-image: url("../../img/icons/white/" + $name + ".svg");
}

@mixin navigation-icon {
  @include icon;
  height: 18px;
  width: 18px;
}

@mixin navigation-outlined($name) {
  @include navigation-icon;
  background-image: url("../../img/icons/navigation/outlined/" + $name + ".svg");
}
@mixin navigation-filled($name) {
  @include navigation-icon;
  background-image: url("../../img/icons/navigation/filled/" + $name + ".svg");
}
