@use "assets/styles/global" as *;

.page.home {
  justify-content: center;
}

.home {
  flex-direction: column;
  align-items: center;

  background-image: url("../../assets/img/background.jpg");

  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  @include md {
    padding: 0 10%;
  }

  .home-title-container {
    text-align: center;
    margin-bottom: 3rem;

    .logo {
      max-height: 60px;
      margin-bottom: 0.5rem;
    }

    .home-title {
      font-size: clamp(22px, 3.5rem, 58px);
    }
  }

  .actions-section {
    border: none;
    padding-top: 0;
  }

  .form-actions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

.home-modal {
  border-radius: 20px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0 auto;

  @include md {
    width: 80%;
  }

  .home-modal-header {
    padding: 1.5rem 1.2rem;
    background-color: $primary-color;

    @include md {
      padding: 1.5rem 2rem;
    }

    .home-modal-header-title {
      font-size: clamp(16px, 1.5rem, 24px);
      color: $brand-text-color;
      text-align: center;
    }
  }

  .home-modal-body {
    background-color: $background-color;
    padding: 2rem 3rem;
  }
}
